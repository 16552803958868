.bucket {
    border: 1px dotted var(--dark);
    padding: 15px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .bucket h4 {
    font-size: 1.3em;
    display: inline-block;
    margin: 20px 0px 20px 0px;
  }
  
  .bucket sub {
    color: var(--dark);
    text-transform: uppercase;
    display: block;
  }

  .allocations b{
    color: var(--dark)
  }


  .matrix {
    width: 100%;
    height: 20px;
    border: 1px solid var(--dark);
    margin-bottom: 10px;
  }



.modal {
    position: fixed;
    display: flex;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: var(--warmbg);
    border-radius: 50px;
    padding: 40px;
    overflow-y: scroll;
    z-index: 10;
    overflow-x: hidden;
    /* max-height: 100vh;
    max-width: 100vw; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    border: 1px solid black;
}

.Allocations::before {
  position: absolute;
  height: 400px;
  border: 1px solid var(--dark);
  left: 0px;
}
  .modal::-webkit-scrollbar {
    display: none;
  }

  .modal h4 {
      margin-top: 100px;
  }

  .modal button {
      border-radius: 50%;
      position: fixed;
      background-color: var(--warmbg);
      transition: .2s;
      border-color: black;
      width: 50px;
      height: 50px;
      color: black;
      font-size: 1.1em;
  }

  .modal button:hover { 
      background-color: var(--bg);
      transition: .2s;
  }

  .bucket_img {
    background-color:var(--warmbg);
    border-radius: 10px;
    width: 100%;
    height: 340px;
    object-fit: cover;
    padding: 1px;
  }