
.Grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .GridMenu {
    width: 100%;
    position: sticky;
    top: 46px;
    z-index: 2;
    padding-bottom: 0px;
    background-color: var(--transparent);
    color: var(--black);
    box-shadow: 0px 10px 10px var(--transparent);
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .GridMenu p {
    margin: 0px;
    padding-bottom: 10px;
  }

  .GridMenu button {
    background-color: var(--dark);
    color: var(--bg);
    border: 1px dotted var(--dark);
  }

  .GridMenu button:hover {
    background-color: var(--bg);
    border: 1px dotted var(--dark);
    color: var(--dark)
  }

  .GridMenu .selected {
    background-color: var(--bg);
    border: 1px solid var(--dark);
    color: var(--dark)
  }

  .GridMenu button::before {
    content: "X "
  }
  
  .GridMenu .selected::before {
    content: "+ "
  }
  
  .GridItem {
    width: calc(50% - 40px);
    width: 100%;
    margin: 5px;
    padding: 15px;
    background-color: var(--warmbg);
    border-radius: 10px;
    font-weight: 500;
    position: relative;
    z-index: 0;
  }
  
  .GridItem a {
    font-family: var(--subFont);
    color: var(--dark);
    font-size: .7em;
    position: absolute;
    border: none;
    top: 20px;
    right: 10px;
    padding: 3px 10px 0px 10px;
    border-radius: 40px;
  }

  .GridItem a:hover {
    background-color: var(--light);
    padding: 3px 10px 0px 10px;
  }
  
  .GridItem p{
    margin: 0px;
  }
  
  .GridItem sub {
    text-transform: uppercase;
    padding: 5px 6px 4px 6px;
    font-size: .7em;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 15px;
    font-weight: 400;
  }
